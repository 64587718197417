<template>
  <ps-nav ref="nav" class="c-app-bar">
    <router-link
      v-slot="{ href, navigate }"
      :to="primaryMenuItems[0].toState"
      custom
    >
      <logo-component type="desktop" :href="href" @click="navigate">
        Contact Center
      </logo-component>
    </router-link>
    <logo-component @click="toggleMobileNav" />
    <div class="c-app-bar__nav-menu">
      <ps-nav-links>
        <router-link
          v-for="item in primaryMenuItems"
          :key="item.toState"
          v-slot="{ href, isActive, navigate }"
          :to="item.toState"
          custom
        >
          <ps-nav-link :href="href" :active="isActive" @click="navigate">
            {{ $t(`SIDEBAR.${item.label}`) }}
          </ps-nav-link>
        </router-link>
      </ps-nav-links>
    </div>
    <div
      class="c-app-bar__mobile-drawer"
      :class="{ 'c-app-bar__mobile-drawer--open': isMobileMenuVisible }"
    >
      <div class="c-app-bar__mask" @click="toggleMobileNav" />
      <div class="c-app-bar__content">
        <div>
          <div class="c-app-bar__mobile-drawer-head">
            <router-link
              v-slot="{ href, navigate }"
              :to="primaryMenuItems[0].toState"
              custom
            >
              <logo-component type="drawer" :href="href" @click="navigate">
                Contact Center
              </logo-component>
            </router-link>
            <fluent-icon
              icon="add"
              class="c-app-bar__mobile-drawer-icon"
              @click.native="toggleMobileNav"
            />
          </div>
          <div class="c-app-bar__mobile-drawer-links row">
            <router-link
              v-for="item in primaryMenuItems"
              :key="item.toState"
              v-slot="{ href, isActive, navigate }"
              :to="item.toState"
              custom
            >
              <a
                :href="href"
                class="c-app-bar__nav-link columns small-12"
                :class="{ 'c-app-bar__nav-link--active': isActive }"
                @click="navigate"
              >
                {{ toUpper(item.label) }}
              </a>
            </router-link>
          </div>
        </div>
        <div class="c-app-bar__mobile-drawer-footer">
          <options-menu-mobile
            @toggle-accounts="toggleAccountModal"
            @show-support-chat-window="toggleSupportChatWindow"
            @key-shortcut-modal="toggleKeyShortcutModal"
            @close="toggleMobileNav"
          />
        </div>
      </div>
    </div>
    <app-bar-controls
      :account-id="accountId"
      @open-notification-panel="openNotificationPanel"
      @toggle-accounts="toggleAccountModal"
      @key-shortcut-modal="toggleKeyShortcutModal"
    />
  </ps-nav>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../mixins/isAdmin';
import { getSidebarItems } from './config/default-sidebar';
import alertMixin from 'shared/mixins/alertMixin';
import AppBarControls from './sidebarComponents/AppBarControls.vue';
import OptionsMenuMobile from './sidebarComponents/OptionsMenuMobile.vue';
import LogoComponent from './sidebarComponents/LogoComponent.vue';

import {
  hasPressedAltAndCKey,
  hasPressedAltAndRKey,
  hasPressedAltAndSKey,
  hasPressedAltAndVKey,
  hasPressedCommandAndForwardSlash,
  isEscape,
} from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import router from '../../routes';

export default {
  components: {
    AppBarControls,
    OptionsMenuMobile,
    LogoComponent,
  },
  mixins: [adminMixin, alertMixin, eventListenerMixins],

  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true,
    },
    sidebarClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
      isMobileMenuVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
      isACustomBrandedInstance: 'globalConfig/isACustomBrandedInstance',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      labels: 'labels/getLabelsOnSidebar',
      teams: 'teams/getMyTeams',
    }),
    activeCustomView() {
      if (this.activePrimaryMenu.key === 'contacts') {
        return 'contact';
      }
      if (this.activePrimaryMenu.key === 'conversations') {
        return 'conversation';
      }
      return '';
    },
    customViews() {
      return this.$store.getters['customViews/getCustomViewsByFilterType'](
        this.activeCustomView
      );
    },
    isConversationOrContactActive() {
      return (
        this.activePrimaryMenu.key === 'contacts' ||
        this.activePrimaryMenu.key === 'conversations'
      );
    },
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    primaryMenuItems() {
      const menuItems = this.sideMenuConfig.primaryMenu;
      return menuItems.filter(menuItem => {
        const isAvailableForTheUser = menuItem.roles.includes(this.currentRole);

        if (!isAvailableForTheUser) {
          return false;
        }

        if (menuItem.featureFlag) {
          return this.isFeatureEnabledonAccount(
            this.accountId,
            menuItem.featureFlag
          );
        }
        return true;
      });
    },
    activeSecondaryMenu() {
      const { secondaryMenu } = this.sideMenuConfig;
      const { name: currentRoute } = this.$route;

      const activeSecondaryMenu =
        secondaryMenu.find(menuItem =>
          menuItem.routes.includes(currentRoute)
        ) || {};
      return activeSecondaryMenu;
    },
    activePrimaryMenu() {
      const activePrimaryMenu =
        this.primaryMenuItems.find(
          menuItem => menuItem.key === this.activeSecondaryMenu.parentNav
        ) || {};
      return activePrimaryMenu;
    },
  },

  watch: {
    activeCustomView() {
      this.fetchCustomViews();
    },
    $route() {
      this.isMobileMenuVisible = false;
    },
  },
  mounted() {
    this.$store.dispatch('labels/get');
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('notifications/unReadCount');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('attributes/get');
    this.fetchCustomViews();

    const root = document.querySelector('ps-nav');

    const style = document.createElement('style');
    style.innerHTML = '.c-nav .c-nav__wrapper { max-width: initial; }';
    root.shadowRoot.appendChild(style);
  },
  methods: {
    fetchCustomViews() {
      if (this.isConversationOrContactActive) {
        this.$store.dispatch('customViews/get', this.activeCustomView);
      }
    },
    toggleKeyShortcutModal() {
      this.$emit('open-key-shortcut-modal');
    },
    closeKeyShortcutModal() {
      this.$emit('close-key-shortcut-modal');
    },
    handleKeyEvents(e) {
      if (hasPressedCommandAndForwardSlash(e)) {
        this.toggleKeyShortcutModal();
      }
      if (isEscape(e)) {
        this.closeKeyShortcutModal();
      }

      if (hasPressedAltAndCKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('home')) {
          router.push({ name: 'home' });
        }
      } else if (hasPressedAltAndVKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('contacts_dashboard')) {
          router.push({ name: 'contacts_dashboard' });
        }
      } else if (hasPressedAltAndRKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('settings_account_reports')) {
          router.push({ name: 'settings_account_reports' });
        }
      } else if (hasPressedAltAndSKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('agent_list')) {
          router.push({ name: 'agent_list' });
        }
      }
    },
    isCurrentRouteSameAsNavigation(routeName) {
      return this.$route.name === routeName;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    toggleAccountModal() {
      this.$emit('toggle-account-modal');
    },
    showAddLabelPopup() {
      this.$emit('show-add-label-popup');
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
    toUpper(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(word => {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
    },
    toggleMobileNav(e) {
      e.preventDefault();
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
    },
    onClickAway() {
      this.isMobileMenuVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/woot';

.c-app-bar {
  z-index: var(--z-index-low);
  &__nav-menu {
    display: none;
    @include breakpoint(large) {
      display: flex;
      justify-content: flex-start;
      padding: 0 48px;
    }
  }

  &__nav-link {
    border-left: 2px solid transparent;
    height: 48px;
    padding-left: 32px;
    color: #62697c;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-self: center;

    &--active {
      color: #8573e7;
      background: rgba(21, 26, 37, 0.025);
      border-color: #8573e7;
    }
  }

  &__mobile-drawer {
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
    transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    transform: translate3d(-100%, 0, 0) translate3d(-24px, 0, 0);
    z-index: 1000;

    &--open {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &__content {
    position: relative;
    width: 330px;
    background-color: $color-white;
    box-shadow: 0px 18px 38px rgba(115, 109, 157, 0.06),
      0px 8px 18px rgba(115, 109, 157, 0.04),
      0px 0px 10px rgba(115, 109, 157, 0.02),
      0px 0px 6px rgba(115, 109, 157, 0.02);
    z-index: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__mask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    background-color: transparent;
  }

  &__mobile-drawer-head {
    padding: 32px 0;
    margin: 0 32px;
    border-bottom: 1px solid #eff0f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__mobile-drawer-links {
    padding: 32px 0;
  }

  &__mobile-drawer-footer {
    padding: 32px;
    height: 100%;
    overflow: auto;
  }

  &__mobile-drawer-icon {
    transform: rotate(45deg);
  }
}
</style>
