/* eslint-disable no-console */
import posthog from 'posthog-js';
import { ANALYTICS_PYPESTREAM_IDENTITY } from '../scriptHelpers';
import store from '../../store';

export const analyticsCaptureHost =
  window?.contactCenterConfig?.analyticsCaptureHost;
export const analyticsCaptureApiKey =
  window?.contactCenterConfig?.analyticsCaptureApiKey;
export const analyticsCaptureEnvNamespace =
  window?.contactCenterConfig?.analyticsCaptureEnvNamespace;

export const PYPESTREAM_ANALYTICS_EVENTS = {
  CONVERSATION_OPENED: 'CC: Conversation Opened',
  CONVERSATION_REOPENED: 'CC: Conversation Reopened',
  CONVERSATION_SNOOZED: 'CC: Conversation Snoozed',
  CONVERSATION_PENDING: 'CC: Conversation Marked As Pending',
  CONVERSATION_RESOLVED: 'CC: Conversation Resolved',
  CONVERSATION_ASSIGNED_AGENT: 'CC: Agent Assigned',
  CONVERSATION_ASSIGNED_TEAM: 'CC: Team Assigned',
  LABEL_ADDED: 'CC: Label Added',
  SET_USER_STATUS_ONLINE: 'CC: Status Set to Online',
  SET_USER_STATUS_BUSY: 'CC: Status Set to Busy',
  SET_USER_STATUS_OFFLINE: 'CC: Status Set to Offline',
};

export const initalizeAnalyticsPypestream = () => {
  if (analyticsCaptureHost && analyticsCaptureApiKey) {
    console.log('[ANALYTICS EVENTS]: Initializing Pypestream Analytics');
    posthog.init(analyticsCaptureApiKey, {
      api_host: analyticsCaptureHost,
    });

    posthog.register({
      'Pypestream Product': 'Contact Center',
      'Pypestream Environment': analyticsCaptureEnvNamespace,
    });
    if (analyticsCaptureEnvNamespace !== 'production') {
      posthog.debug();
    }
  }
};

export const identifyUser = ({ user }) => {
  console.log('[ANALYTICS EVENTS]: Identifying User');
  posthog.identify(user.id, {
    name: user.name,
    email: user.email,
    user_type: user.role === 'administrator' ? 'Agent Manager' : 'Agent',
  });
};

export const turnOffAnalyticsCapture = () => {
  console.log('[ANALYTICS EVENTS]: Turning Off Analytics Capture');

  posthog.opt_out_capturing();
  posthog.stopSessionRecording();
};

export const turnOnAnalyticsCapture = () => {
  console.log('[ANALYTICS EVENTS]: Turning On Analytics Capture');

  posthog.opt_in_capturing();
  posthog.startSessionRecording();
};

export const identityAnalitycsPypestreamUser = () => {
  window.bus.$on(ANALYTICS_PYPESTREAM_IDENTITY, identifyUser);
};

export const buildPypestreamEventProperties = eventType => {
  const currentUserAvailability = store.getters.getCurrentUserAvailability;
  const account = store.getters.getCurrentAccount;

  const defaultProps = {
    user_status: currentUserAvailability,
    project: account?.name,
  };
  if (eventType === 'conversation') {
    const currentChat = store.getters.getSelectedChat;
    const {
      meta,
      inbox_id,
      custom_attributes,
      labels,
      priority,
      status,
    } = currentChat;
    const { sender, team, assignee } = meta;
    const currentInbox = store.getters['inboxes/getInbox'](inbox_id);
    return {
      ...defaultProps,
      contact: sender?.email ?? sender?.name,
      inbox: currentInbox?.name,
      assigned_team: team?.name,
      assigned_agent: assignee?.email ?? assignee?.name,
      attributes: { ...custom_attributes },
      labels: [...labels],
      priority,
      participants: 'not available yet',
      conversation_status: status,
    };
  }

  return defaultProps;
};

export const captureCustomAnalyticsPypestreamEvent = ({
  eventName,
  isConversationEvent,
}) => {
  if (isConversationEvent) {
    return posthog.capture(
      eventName,
      buildPypestreamEventProperties('conversation')
    );
  }
  return posthog.capture(eventName, buildPypestreamEventProperties());
};
