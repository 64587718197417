import { render, staticRenderFns } from "./ChatList.vue?vue&type=template&id=1fba088f&scoped=true&"
import script from "./ChatList.vue?vue&type=script&lang=js&"
export * from "./ChatList.vue?vue&type=script&lang=js&"
import style0 from "./ChatList.vue?vue&type=style&index=0&id=1fba088f&prod&scoped=true&lang=css&"
import style1 from "./ChatList.vue?vue&type=style&index=1&id=1fba088f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fba088f",
  null
  
)

export default component.exports