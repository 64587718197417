<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <p
          v-if="!teamsList.length"
          class="flex h-full items-center flex-col justify-center"
        >
          {{ $t('TEAMS_SETTINGS.LIST.404') }}
          <!-- <router-link
            v-if="isAdmin"
            :to="addAccountScoping('settings/teams/new')"
          >
            {{ $t('TEAMS_SETTINGS.NEW_TEAM') }}
          </router-link> -->
        </p>

        <div v-if="teamsList.length" class="woot-table">
          <div
            v-for="item in teamsList"
            :key="item.id"
            class="border-b border-pype-grey-200"
          >
            <div class="flex justify-between items-center py-4">
              <div>
                <span class="team-name">{{ item.name }}</span>
                <p class="m-0">{{ item.description }}</p>
              </div>
              <div>
                <woot-button
                  v-if="isAdmin"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  icon="chevron-down"
                  :class="{ 'rotate-180': isActiveState(item.id) }"
                  @click="onShowTeamMembersClick(item.id)"
                />
              </div>
            </div>
            <div v-if="isActiveState(item.id)">
              <div v-if="UIFlags.isFetching">
                <span class="spinner mt-4 mb-4" />
              </div>
              <div v-else class="flex gap-2 align-middle">
                <table class="woot-table">
                  <tbody>
                    <tr
                      v-for="(agent, idx) in getTeamMembers(item.id)"
                      :key="agent.email"
                      :class="{
                        'border-b-0':
                          getTeamMembers(item.id).length - 1 === idx,
                      }"
                    >
                      <td>
                        <thumbnail
                          :src="agent.thumbnail"
                          class="columns ph-no-capture"
                          :username="agent.name"
                          size="40px"
                          :status="agent.availability_status"
                        />
                      </td>
                      <td>
                        <span class="agent-name ph-no-capture">
                          {{ agent.name }}
                        </span>
                        <span class="ph-no-capture">{{ agent.email }}</span>
                      </td>
                      <td>
                        <span class="agent-name ph-no-capture">
                          {{
                            $t(
                              `AGENT_MGMT.AGENT_TYPES.${agent.role.toUpperCase()}`
                            )
                          }}
                        </span>
                        <span v-if="agent.confirmed">
                          {{ $t('AGENT_MGMT.LIST.VERIFIED') }}
                        </span>
                        <span v-if="!agent.confirmed">
                          {{ $t('AGENT_MGMT.LIST.VERIFICATION_PENDING') }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <td>
                <div class="button-wrapper">
                  <router-link
                    :to="addAccountScoping(`settings/teams/${item.id}/edit`)"
                  >
                    <woot-button
                      v-if="isAdmin"
                      v-tooltip.top="$t('TEAMS_SETTINGS.LIST.EDIT_TEAM')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      class-names="grey-btn"
                      icon="settings"
                    />
                  </router-link>
                  <woot-button
                    v-if="isAdmin"
                    v-tooltip.top="$t('TEAMS_SETTINGS.DELETE.BUTTON_TEXT')"
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    icon="dismiss-circle"
                    class-names="grey-btn"
                    :is-loading="loading[item.id]"
                    @click="openDelete(item)"
                  />
                </div>
              </td> -->
          </div>
        </div>
      </div>

      <div class="w-[34%]">
        <span
          v-dompurify-html="
            $t('TEAMS_SETTINGS.SIDEBAR_TXT', {
              installationName: globalConfig.installationName,
            })
          "
        />
      </div>
    </div>
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="confirmDeleteTitle"
      :message="$t('TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedTeam.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';

export default {
  components: {
    Thumbnail,
  },
  mixins: [adminMixin, accountMixin, alertMixin],
  data() {
    return {
      loading: {},
      showSettings: false,
      showDeletePopup: false,
      selectedTeam: {},
      activeTeam: null,
    };
  },
  computed: {
    ...mapGetters({
      teamsList: 'teams/getTeams',
      globalConfig: 'globalConfig/get',
      getTeamMembers: 'teamMembers/getTeamMembers',
      UIFlags: 'teamMembers/getUIFlags',
    }),
    deleteConfirmText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedTeam.name
      }`;
    },
    deleteRejectText() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.TITLE', {
        teamName: this.selectedTeam.name,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.PLACE_HOLDER', {
        teamName: this.selectedTeam.name,
      })}`;
    },
  },
  methods: {
    async deleteTeam({ id }) {
      try {
        await this.$store.dispatch('teams/delete', id);
        this.showAlert(this.$t('TEAMS_SETTINGS.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('TEAMS_SETTINGS.DELETE.API.ERROR_MESSAGE'));
      }
    },
    async fetchTeamMembers(teamId) {
      await this.$store.dispatch('teamMembers/get', {
        teamId,
      });
    },
    confirmDeletion() {
      this.deleteTeam(this.selectedTeam);
      this.closeDelete();
    },
    openDelete(team) {
      this.showDeletePopup = true;
      this.selectedTeam = team;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedTeam = {};
    },
    onShowTeamMembersClick(teamId) {
      if (this.activeTeam === teamId) {
        this.activeTeam = null;
      } else {
        this.activeTeam = teamId;
        this.fetchTeamMembers(teamId);
      }
    },
    isActiveState(teamId) {
      return this.activeTeam === teamId;
    },
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  min-width: unset;
  justify-content: flex-end;
  padding-right: var(--space-large);
}
</style>
