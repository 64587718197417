<template>
  <div
    v-if="!authUIFlags.isFetching"
    id="app"
    class="app-wrapper h-screen flex-grow-0 min-h-0 w-full"
    :class="{ 'app-rtl--wrapper': isRTLView }"
    :dir="isRTLView ? 'rtl' : 'ltr'"
  >
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <!-- <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    /> -->
    <woot-snackbar-box />
    <network-notification />
    <session-expired-modal />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
// import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification';
import SessionExpiredModal from './components/SessionExpiredModal';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer';
import rtlMixin from 'shared/mixins/rtlMixin';
import { setColorTheme } from './helper/themeHelper';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';
import '@pypestream/design-system/dist/design-system';
import {
  checkSessionHealth,
  CHECKS_SESSION_HEALTH_INTERVAL,
} from './helper/KratosHelper';

export default {
  name: 'App',

  components: {
    // AddAccountModal,
    LoadingState,
    NetworkNotification,
    WootSnackbarBox,
    SessionExpiredModal,
  },

  mixins: [rtlMixin],

  data() {
    return {
      // showAddAccountModal: false,
      scriptValue: `
      window.contactCenterSettings = {"position":"left"};
      (function(d,t) {
        var BASE_URL="https://contactcenter.pypestream.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.contactCenterSDK.run({
            websiteToken: 'ymzBHmns2SF8k9WML9Cmm89f',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");`,
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      accountUIFlags: 'accounts/getUIFlags',
      currentAccountId: 'getCurrentAccountId',
      sessionExpireDate: 'getExpirationDate',
    }),
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
  },

  watch: {
    // currentUser() {
    //   if (!this.hasAccounts) {
    //     this.showAddAccountModal = true;
    //   }
    // },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
  },
  mounted() {
    this.setLocale(window.contactCenterConfig.selectedLocale);
    window.onfocus = this.onTabFocus;
    this.renderSupportScript();
    window.setInterval(async () => {
      await checkSessionHealth();
    }, CHECKS_SESSION_HEALTH_INTERVAL);
  },
  methods: {
    async onTabFocus() {
      await checkSessionHealth();
    },
    renderSupportScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = this.scriptValue;
      document.body.appendChild(script);
    },
    initializeColorTheme() {
      setColorTheme(window.matchMedia('(prefers-color-scheme: dark)').matches);
    },
    listenToThemeChanges() {
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      mql.onchange = e => setColorTheme(e.matches);
    },
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');
      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });
      const { locale } = this.getAccount(this.currentAccountId);
      const { pubsub_token: pubsubToken } = this.currentUser || {};
      this.setLocale(locale);
      this.updateRTLDirectionView(locale);
      vueActionCable.init(pubsubToken);

      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
