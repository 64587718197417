import { createConsumer } from '@rails/actioncable';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import * as Sentry from '@sentry/vue';

const PRESENCE_INTERVAL = 20000;
const RECONNECT_INTERVAL = 1000;

class BaseActionCableConnector {
  static isDisconnected = false;

  constructor(app, pubsubToken, websocketHost = '') {
    const websocketURL = websocketHost ? `${websocketHost}/cable` : undefined;

    this.consumer = createConsumer(websocketURL);
    // eslint-disable-next-line no-console
    console.log('===websocketURL :', websocketURL);
    // eslint-disable-next-line no-console
    console.log('===consumer-created :', this.consumer);
    // eslint-disable-next-line no-console
    console.log('===pubsubToken :', pubsubToken);
    this.subscription = this.consumer.subscriptions.create(
      {
        channel: 'RoomChannel',
        pubsub_token: pubsubToken,
        account_id: app.$store.getters.getCurrentAccountId,
        user_id: app.$store.getters.getCurrentUserID,
      },
      {
        updatePresence() {
          this.perform('update_presence');
        },
        connected: this.onConnected,
        received: this.onReceived,
        rejected() {
          // eslint-disable-next-line no-console
          console.log('Subscription rejected');
        },
        disconnected: () => {
          Sentry.captureMessage(`[Action Cable]: disconnect;
            CurrentUser: ${JSON.stringify(
              app.$store.getters.getCurrentUser || ''
            )}
            CurrentAccount: ${JSON.stringify(
              app.$store.getters.getCurrentAccount || ''
            )}`);
          BaseActionCableConnector.isDisconnected = true;
          this.onDisconnected();
          this.onDisconnectedUI();
          this.initReconnectTimer();
        },
      }
    );
    this.app = app;
    this.events = {};
    this.reconnectTimer = null;
    this.isAValidEvent = () => true;
    this.triggerPresenceInterval = () => {
      setTimeout(() => {
        this.subscription.updatePresence();
        this.triggerPresenceInterval();
      }, PRESENCE_INTERVAL);
    };
    this.triggerPresenceInterval();
  }

  checkConnection() {
    this.reOpenConnection();
    const isConnectionActive = this.consumer.connection.isOpen();
    const isReconnected =
      BaseActionCableConnector.isDisconnected && isConnectionActive;
    if (isReconnected) {
      this.refetchConversations();
      this.clearReconnectTimer();
      this.onReconnect();
      this.onReconnectUI();
      BaseActionCableConnector.isDisconnected = false;
    } else {
      this.initReconnectTimer();
    }
  }

  clearReconnectTimer = () => {
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }
  };

  initReconnectTimer = () => {
    this.clearReconnectTimer();
    this.reconnectTimer = setTimeout(() => {
      this.checkConnection();
    }, RECONNECT_INTERVAL);
  };

  refetchConversations = () => {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: Refetch All Conversations');
    window.bus.$emit(BUS_EVENTS.REFETCH_CONVERSATIONS);
  };

  onReconnect = () => {
    // eslint-disable-next-line no-console
    console.log('===onReconnect===:');
  };

  onDisconnected = () => {
    // eslint-disable-next-line no-console
    console.log('===onDisconnected===');
  };

  onReconnectUI = () => {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: Hide Disconnected Toast');
    window.bus.$emit(BUS_EVENTS.WEBSOCKET_RECONNECT);
  };

  onDisconnectedUI = () => {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: Show Disconnected Toast');
    window.bus.$emit(BUS_EVENTS.WEBSOCKET_DISCONNECT);
  };

  disconnect() {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: Disconnect');
    this.consumer.disconnect();
  }

  reOpenConnection = () => {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: Reconnection');
  };

  onReceived = ({ event, data } = {}) => {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: eventReceived', event);
    if (this.isAValidEvent(data)) {
      if (this.events[event] && typeof this.events[event] === 'function') {
        this.events[event](data);
      }
    }
  };

  onConnected = () => {
    // eslint-disable-next-line no-console
    console.log('[Action Cable]: Connected ', this.subscription);
    this.onReconnectUI();
  };
}

export default BaseActionCableConnector;
